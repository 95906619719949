/* ===========================
Category section - component
=========================== */
.c-category-section {
    background-color: var(--white);
    padding: var(--space-8) 0;

    @include breakpoint(min-768) {
        padding: var(--space-16) 0;
    }

    @include breakpoint(min-1280) {
        padding: var(--space-20) 0;
    }
}

.c-category-section__content {
    text-align: center;
    margin: 0 auto rem(32) auto;
    max-width: rem(640);

    @include breakpoint(min-768) {
        margin: 0 auto rem(40) auto;
    }

    @include breakpoint(min-1280) {
        margin: 0 auto rem(60) auto;
    }
}

.c-category-section__heading {
    display: block;
    text-align: center;
    margin-bottom: var(--space-2);
   
    @include breakpoint(min-768) {
        margin-bottom: var(--space-4);
    }
}

.c-category-section__desc {
    display: block;
    text-align: center;
    line-height: rem(22);
    margin: 0;
}

// Category items
.c-category-section__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--space-4) var(--space-2);

    @include breakpoint(min-960) {
        grid-template-columns: repeat(4, 1fr);
    }
}
.c-category-section__item {
    display: flex;
}

.c-category-section__item a {
    display: flex;
    flex-direction: column;
    gap: var(--space-1);
    text-decoration: none;

    @include breakpoint(min-768) {
        rid-template-columns: repeat(3, 1fr);
        gap: var(--space-2);
    }

    &:hover {
        text-decoration: underline;

        img {
            transform: scale(1.1);
        }
    }

    @media (hover: none) {
        &:hover {
            text-decoration: none;
    
            img {
                transform: scale(1);
            }
        }
      }

    &:focus {
        outline: none;
        background-color: transparent;

        img {
            box-shadow: 0 0 0 var(--space-05) #7c999a;
        }
    }
}

.c-category-section__item picture {
   overflow: hidden;
   display: flex;
}

.c-category-section__item img {
    width: 100%;
    height: auto;
    display: inline-block;
    transition: transform .2s $ease-in-out-quad;
}